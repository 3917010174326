<template>
  <div >
  <notification :message="message"></notification>
    <on-site-id-top-header-template>
        <template slot-scope="{logo}">
            <fetch-siren-root :linkName='linkName' :link="link" :logo="logo" @unauthorised="$router.push('/mobile/dashboard')">

                <div slot-scope="{ response, logo }">
                    <loading v-if="!response"></loading>
                    <div v-else>
                        <div class="container mx-auto px-4 sm:px-6 lg:px-8">
                            <div v-if="getRequestAccessAction(response)" class="grid grid-cols-1 sm:grid-cols-3">
                                <div  class=" mt-8 max-h-100 p-4 w-full">
                                    <div class="mx-auto container">
                                        <form-action
                                            :action="getRequestAccessAction(response)"
                                            :action-key="getRequestAccessAction(response).name"
                                            :redirect-on-success="true"
                                            additional-button-classes="w-full"
                                            :on-success-path="$router.currentRoute.fullPath"
                                        ></form-action>
                                    </div>
                                </div>
                            </div>
                            <div v-if="response.body.properties.get('pending_employer_code') === null" class="bg-secondary-appdarkblue opacity-75 rounded-xl mt-8 max-h-100 p-4 border-2 border-secondary-appdarkblue w-full">
                                <div class="mx-auto container">
                                    <dt class="text-base uppercase font-extrabold text-gray-300">Pending Employer Code</dt>
                                    <dt class="text-base uppercase font-extrabold text-white">No Pending Employer Access</dt>
                                </div>
                            </div>
                            <div v-else class="bg-secondary-appdarkblue opacity-75 rounded-xl mt-8 max-h-100 p-4 border-2 border-secondary-appdarkblue w-full">
                                <div class="mx-auto container">
                                    <dt class="text-base uppercase font-extrabold text-gray-300">Pending Employer Code</dt>
                                    <dt class="text-base uppercase font-extrabold text-white">{{ response.body.properties.get('pending_employer_code') }}</dt>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </fetch-siren-root>
        </template>
    </on-site-id-top-header-template>
  </div>
</template>

<script>
import AssignOnSiteIdAction from "@/v2/components/AssignOnSiteIdAction.vue";
import Button from "@/v2/buttons/Button.vue";
import ConfirmationAction from "@/v2/components/ConfirmationAction.vue";
import FetchSirenRoot from "@/components/FetchSirenRoot.vue";
import FormAction from "@/v2/components/FormAction.vue";
import Loading from "@/components/Loading.vue";
import { mapActions, mapGetters } from "vuex";
import Notification from "@/components/Notification.vue";
import TopHeaderTemplate from "@/templates/TopHeaderTemplate.vue";
import OnSiteIdTopHeaderTemplate from "@/templates/OnSiteIdTopHeaderTemplate.vue";

export default {
  components: {
      FormAction,
      AssignOnSiteIdAction,
      'a-button': Button,
      ConfirmationAction, OnSiteIdTopHeaderTemplate, Loading, FetchSirenRoot, TopHeaderTemplate, Notification },

  data() {
    return {
    };
  },
  computed: {
      linkName() {
          return null;
      },
      link(){
          // if(Object.keys(this.$route.query).length === 0){
          //     return '/orders';
          // }
          return this.$route.fullPath.replace('/v3','')
      },
    ...mapGetters({
         accessToken: "getAccessToken",
        message: "getMessage",
        messageWithoutTimeout: "getMessageWithoutTimeout"
    })
  },
  mounted() {
    // if (this.accessToken !== null) {
    //   Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
    //   return Siren.get(process.env.VUE_APP_API_URL + "/api/").then(res => {
    //     this.$router.push({ path: "/" });
    //   });
    // }
  },

  methods: {
      getRequestAccessAction(response){
          return response.body.actions.filter(action => action.name === 'request-client-access').first();
      },
      hasLink(response, linkName){
          return response.body.links.filter(link => link.rels.contains(linkName)).first();
      },
      navigateToProfile(response) {
          let selfLink = response.body.links.filter(link => link.rels.contains('profile')).first().href;
          let parts = selfLink.split('/api');
          this.$router.push(parts[1]);
      },
      navigateToMyOnSiteId(response) {
          let selfLink = response.body.links.filter(link => link.rels.contains('my-on-site-id')).first().href;
          let parts = selfLink.split('/api');
          this.$router.push(parts[1]);
      },
      navigateToEmployerSettings(response) {
          let selfLink = response.body.links.filter(link => link.rels.contains('employer-settings')).first().href;
          let parts = selfLink.split('/api');
          this.$router.push(parts[1]);
      },
  },
};
</script>

<style>
body{
    background-color:red
}
*{
    margin:0;
}
</style>
